import React from 'react';
// import { Layout } from '../components/Layout';

const NotFoundPage = () => (
  <div className="flex items-center justify-center">
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p>...</p>
  </div>
);

export default NotFoundPage;
